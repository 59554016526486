// @ts-ignore
const $ = window.jQuery;

$(document).ready(function() {
  const sliders = document.querySelectorAll('.slideshow');
  sliders.forEach(slider => {
    const isMany = slider.childElementCount > 1;

    if (!isMany) {
      $('.slideshow').css({ visibility: 'visible' });
      return;
    }

    $('.slideshow').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: isMany,
      autoplaySpeed: 4000,
      arrows: isMany,
      dots: isMany,
      pauseOnHover: false,
    });
  });
});
