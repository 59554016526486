import { ModalStore } from './ModalStore';

export function removeHash() {
  history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search,
  );
}

export function detectHashAndDisplayModal() {
  if (window.location.hash) {
    try {
      const anchorLink = document.querySelector(
        decodeURI(window.location.hash),
      ) as HTMLAnchorElement;
      if (anchorLink) {
        ModalStore.store.displayModal(anchorLink.dataset.item);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
