import { isAtURL, isWindownOnPathname } from './utils/urlhelper';

// For desktop
//intercept all a#href calls and scroll to them
const $ = window.jQuery;
$('a[href*=#]').click(function(e) {
  if (isWindownOnPathname(this.pathname)) {
    var dest = this.href;
    dest = '#' + dest.split('#')[1];
    if (dest === '#') {
      return;
    }
    e.preventDefault();
    const destElement = $(dest);
    if (!destElement.offset()) {
      return;
    }
    $('html,body').animate({ scrollTop: destElement.offset().top }, 'slow');
    // const title = this.textContent.trim();
    // history.pushState(null, title, this.href);
  }
});
//on page load. Add the active class if we are at that url.
document
  .querySelectorAll('.nav-list a')
  .forEach((element: HTMLAnchorElement) => {
    if (isAtURL(element.href)) {
      element.classList.add('active');
    }
  });

// Note: moved away from react app for performance
// We now use a css only solution

// disableBodyScroll is not perfect but works for most cases
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const targetElement = document.querySelector('#menu');
document
  .querySelector<HTMLInputElement>('#menuToggle input')
  .addEventListener('change', e => {
    const checked = (e.currentTarget as HTMLInputElement).checked;
    if (checked) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
  });
