export function removeTrailingSlash(site) {
  return site.replace(/\/$/, '');
}

export function isAtURL(url) {
  return removeTrailingSlash(window.location.href) === removeTrailingSlash(url);
}
export function isWindownOnPathname(url) {
  return (
    removeTrailingSlash(window.location.pathname) === removeTrailingSlash(url)
  );
}
