import 'animate.css';

const windowHeight = window.innerHeight;

const $ = window.jQuery;
/* Every time the window is scrolled ... */
function shouldReveal() {
  $('.reveal:not(.show)').each(function() {
    // const height = $(this).outerHeight();
    const top_of_object = $(this).position().top + 100;
    const bottom_of_window = $(window).scrollTop() + windowHeight;

    /* If the object is completely visible in the window, fade it it */
    if (bottom_of_window > top_of_object) {
      const animation = this.dataset.animation || 'fadeIn';
      $(this).addClass(`animated show ${animation}`);
    }
  });
}

const onscroll = () => {
  shouldReveal();
};
$(window).scroll(() => {
  window.requestAnimationFrame(onscroll);
});
onscroll();
